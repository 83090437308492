<template>
  <div id="a">
    <div class="root">
      <div class="center">
        <img :src="logoLink" alt="" />
        <div class="lian">
          <el-dropdown @command="translate">
            <span class="el-dropdown-link">
              {{ $t("home.language") }}
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="en">English</el-dropdown-item>
                <el-dropdown-item command="zh">中文</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <button @click="LineClick">
            {{ ownadress == "连接钱包" ? $t("home.qianbao") : ownadress }}
          </button>

          <el-dropdown>
            <span class="el-dropdown-link">
              <img src="../../assets/导航 (1).png" alt="" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="Plus">
                  <a href="#a" target="_self">{{ $t("home.shouye") }}</a>
                </el-dropdown-item>
                <el-dropdown-item :icon="CirclePlusFilled">
                  <a href="#b">{{ $t("home.yaoqing") }}</a>
                </el-dropdown-item>
                <el-dropdown-item :icon="CirclePlus">
                  <a href="#c">{{ $t("home.canyu") }}</a>
                </el-dropdown-item>
                <el-dropdown-item :icon="Check">
                  <a href="#d">{{ $t("home.hezuo") }}</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import i18n from "../../language/index.js";
import { useI18n } from "vue-i18n";
import { storeToRefs, reactive } from "pinia";
import { useStore } from "../../store/index";
const { t } = useI18n();
import {
  ArrowDown,
  Check,
  CircleCheck,
  CirclePlus,
  CirclePlusFilled,
  Plus,
} from "@element-plus/icons-vue";
import { line } from "../../utils/metamask";
const store = useStore();
const { chainId, logoLink, ownadress } = storeToRefs(store);
const { locale } = useI18n();
const LineClick = () => {
  line();
};
// 中英文转换
const translate = (lang) => {
  // console.log(locale.value)
  locale.value = lang;
};

</script>
<style scoped>
.root {
  background-color: #222222;
  width: 100%;
}

.center {
  height: 100px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 24px;
  margin: 0 auto;
}
.center img {
  height: 90px;
}
.lian img {
  display: none;
}

.lian {
  display: flex;
  align-items: center;
  position: relative;
}

span {
  font-size: 24px;
  font-family: Arial;
}

.lian button {
  width: 200px;
  height: 56px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff;
  border: none;
  margin-left: 35px;
  background: linear-gradient(to right, #f09d25, #fe6b01);
}

.tiaozhuan {
  width: 62px;
  height: 100px;
  background: red;
  position: absolute;
  right: -19px;
  top: 43px;
  z-index: 9999;
  display: none;
}

.line {
  height: 2px;
  background: linear-gradient(90deg, #fe6b01 0%, #f09d25 48%, #fe6b01 100%);
  opacity: 0.25;
}

@media screen and (max-width: 800px) {
  .root {
    position: fixed;
    z-index: 99;
  }
  .center {
    height: 50px;
  }

  .center > img:nth-child(1) {
    height: 35px;
  }

  .lian img {
    display: block;
    width: 28px;
    height: 19px;
  }
  .lian img:hover + .tiaozhuan {
    display: block;
    background: blue;
  }
  .lian {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  span {
    font-size: 14px;
  }

  .lian button {
    margin: 0 12px;
    width: 85px;
    height: 33px;
    font-size: 14px;
    border-radius: 8px;
    /* background: red; */
  }
  .block-col-2 .demonstration {
    display: block;
    /* color: var(--el-text-color-secondary); */
    font-size: 14px;
    margin-bottom: 20px;
  }
  .el-dropdown-link:focus {
    outline: none;
  }
  .block-col-2 .el-dropdown-link {
    display: flex;
    align-items: center;
  }
  .el-dropdown:focus.is-active.is-focus:not(:active) {
    box-shadow: none;
  }
  a {
    text-decoration: none;
    color: #727377;
  }
}
</style>
