<template>
  <div class="root">
    <div class="title">{{ store.title }}</div>
    <div id="c">
      <div class="center">
        <div class="code">
          <canvas ref="qrCanvas"> </canvas>
        </div>
        <div class="content">
          <div>
            <span>{{ $t("home.guize") }}</span>
            <p>
              {{ $t("home.canjiaido") }}
            </p>
          </div>
          <p class="invite">
            {{ $t("home.lianjie") }}
          </p>
          <button @click="copyMyLinkHandler">
            {{ $t("home.fuzhi") }}
          </button>
        </div>
        <div>
          <img src="../../../assets/组 17.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../../store";
import QRCode from "qrcode";
import { Huiyuan } from "../../../config";
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { clipboardCopy } from "clipboard-copy";
import { ElMessage } from "element-plus";
const store = useStore();
const qrCanvas = ref(null);
const text = window.location.origin + "/?" + "invite" + "=" + store.account;
console.log(text, "text");
onMounted(() => {
  QRCode.toCanvas(qrCanvas.value, text, (error) => {
    console.log(qrCanvas.value, "qrCanvas.value");
    if (error) console.error(error);
  });
});
const copyToClipboard = (str) => {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(str);
      ElMessage({
        message: `复制成功`,
        type: "success",
      });
    } else {
      alert("浏览器不支持直接复制到剪贴板，请尝试其他方法。");
    }
  } catch (err) {
    console.error("复制代码时出错:", err);
    ElMessage({
      message: `复制出错，请尝试再次操作。`,
      type: "error",
    });
  }
};

const copyMyLinkHandler = () => {
  const host = window.location.host;
  if (store.idoAddress && store.account) {
    // console.log(store.account, "store.account");
    if (Huiyuan[host]) {
      const str =
        window.location.origin + "/?" + "invite" + "=" + store.account;
      // console.log(str);
      copyToClipboard(str);
    } else {
      const str =
        window.location.origin + "/?" + "invite" + "=" + store.account;
      // console.log(str);
      copyToClipboard(str);
    }
  } else {
    ElMessage({
      message: `请先连接钱包`,
      type: "error",
    });
  }
};
</script>
<style scoped>
.root {
  /* width: 80%; */
  padding-top: 92px;
  background-size: 100% 100%;
  background-image: url("../../../assets/图层 53.png");
  overflow: hidden;
  padding-bottom: 100px;
}
.title {
  color: #fff;
  width: 60%;
  margin: 40px auto;
  text-align: center;
  font-size: 20px;
}
.code {
  width: 200px;
  height: 200px;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
.center {
  width: 90%;
  border-radius: 45px;
  color: #fff;
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to left bottom, #1a1a1a, #000, #1a1a1a),
    linear-gradient(156deg, #f0ab48, #1a1a1a, #cb844f);
  height: 428px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}

.content span {
  font-size: 50px;
  font-family: "造字工房悦圆（非商用）";
}

.content p {
  font-size: 28px;
}

.content p:nth-child(2) {
  margin-top: 28px;
}

.content p:nth-child(3) {
  margin-top: 47px;
}

.center button {
  width: 362px;
  height: 70px;
  background: linear-gradient(90deg, #f09d25 0%, #fe6b01 100%);
  border-radius: 16px;
  font-size: 28px;
  color: #fff;
  margin-top: 23px;
  border: none;
}

.center div:nth-child(3) img {
  position: relative;
  top: 45px;
  right: 0;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  .root {
    padding: 37px 21px 32px;
  }
  .code{
    width: 100px;height: 100px;
  }
  .root:target {
    margin-top: 20000px;
  }
  .title {
    margin: 20px auto;
    font-size: 12px;
    width: 90%;
  }

  .center {
    position: relative;
    border-radius: 22px;
    height: 228px;
    padding: 85px 0 0 28px;
    box-sizing: border-box;
    border-radius: 22px;
    width: 100%;
    justify-content: flex-start;
  }
  .center .content {
    margin-left: 16px;
  }
  .center .content div {
    position: absolute;
    top: 30px;
    left: 28px;
  }

  .content p:nth-child(2) {
    margin-top: 10px;
  }

  .content div span {
    font-size: 25px;
  }

  .center .content p {
    font-size: 14px;
  }

  .center > img {
    width: 84px;
    height: 84px;
  }

  .center .content button {
    width: 181px;
    height: 35px;
    border-radius: 8px;
    border: none;
    font-size: 14px;
  }

  .center div:nth-child(3) {
    display: none;
  }
}
</style>
