import pinia from "../store/pinia";
import { useStore } from "../store";
const store = useStore(pinia);
import { ethers } from "ethers";
import { IDOABI } from "../config";
import { ElMessage } from "element-plus";
export const toHex = (num) => {
  return "0x" + num.toString(16);
};
export const switchEthereumChain = async (_chainId) => {
  // console.log(_chainId.value, "_chainId");
  try {
    const hexChainId = toHex(Number(_chainId.value));
    // console.log(hexChainId,'hexChainId')
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: hexChainId }],
    });
    // console.log(true)
    return true;
  } catch (error) {
    console.error("Error switching chain:", error.message, error.stack);
    if (error.code === 4001) {
      console.log("User denied the chain switch request");
    }
    console.log(11111111);
  }
};
export const line = async () => {
  if (window.ethereum) {
    const web3Provider = window.ethereum;
    try {
      const addressArray = await web3Provider.request({
        method: "eth_requestAccounts",
      });
      store.setAccount(addressArray[0]);
      // console.log(store);
      // store.accountChange(store.account)
      // console.log(store.account,'store.account')
      // 以钱包地址赋给ownadress
      store.ownadressChange(
        addressArray[0].slice(0, 4) + "..." + addressArray[0].slice(-4)
      );
    } catch (error) {
      console.log(error);
    }
  }
};
export const claimTokenAction = async () => {
  const idoAddress = store.idoAddress;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(idoAddress, IDOABI, signer);
    console.log(contract, "contract");
    const res = await contract.claim();
    console.log(res,'res11111111111111111111')
    provider.once(res.hash, (receipt) => {
      console.log("first");
    });
    ElMessage({
      message: `领取成功`,
      type: "success",
    });
    // console.log(res, "res");
    // getUserInfoAction();
  } catch (error) {
    // console.log(1)
    ElMessage({
      message: `暂未开放领取`,
      type: "error",
    });
    console.log(error);
  }
};
