export { default as showtime } from "./timing.js";
import { ERC20ABI, IDOABI } from '../config'
import { ethers } from 'ethers';

// 毫秒
export const getTime = (time) => {
  //将时间戳转换成时间格式
  // d.cTime = 1539083829787
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let mon = `${month}`;
  if (month < 10) mon = `0${month}`;

  let day = date.getDate();
  let d = `${day}`;
  if (day < 10) d = `0${day}`;

  let hour = date.getHours();
  let h = `${hour}`;
  if (hour < 10) h = `0${hour}`;

  let min = date.getMinutes();
  let m = `${min}`;
  if (min < 10) m = `0${min}`;

  return `${year}-${mon}-${d} ${h}:${m}`;
};
export function isZero(hexNumberString) {
  return /^0x0*$/.test(hexNumberString);
}
