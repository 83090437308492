export default {
  home: {
    language: "English",
    qianbao: "Connect Wallet",
    minmairu: "Minimum Buy",
    maxmairu: "Maximum Buy",
    tian: "Day",
    xiaoshi: "Hour",
    minute: "Min",
    miao: "Second",
    jiage: "price",
    shijian: "time",
    taidabi: "TEDA Coin:",
    goumai: "buy",
    minmeiyuan: "Award",
    baochou: "Reward",
    shuliang: "quantity",
    shoudao: "receive",
    guize: "Invitation Rules",
    canjiaido: "You must participate in ido to receive referral rewards:",
    lianjie: "Invitation link",
    fuzhi: "copy",
    huoban: "Our partners",
    sheji: "© 2024. Designer:",
    shouye:"home page",
    yaoqing:"invite",
    canyu:"participate",
    hezuo:"cooperation",
    meiyuan:"dollar",
    min:"min",
    max:"max",
    yushouweikaishi:"Pre sale not yet started",
    yushoukaishi:"Pre sale begins",
    yushoujieshu:"End of pre-sale",
  },
};
