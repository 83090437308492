import { useStore } from "../store/index";
import pinia from "../store/pinia";
import { storeToRefs } from "pinia";
import { showtime } from "@/utils";
import { getTime } from "@/utils";
export default function useGetTimingTime() {
  const store = useStore(pinia);
  const { startTime, endTime } = storeToRefs(store);
  // 创建一个计时器
  const timer = setInterval(() => {
    const newTime = Date.now();
    // 如果现在时间小于开始时间设置时间开始
    // if (newTime < startTime) {
    //   // store.setTime(startTime);
    // }
    // 如果现在时间大于等于开始时间设置时间结束
    if (newTime >= startTime.value) {
      const time = showtime(endTime);
      const day = time[0];
      const hour = time[1];
      const minute = time[2];
      const second = time[3];
      store.dayChange(day);
      store.hourChange(hour);
      store.minuteChange(minute);
      store.secondChange(second);
      const end = getTime(endTime.value);
      const start = getTime(startTime.value);
      store.buyStartChange(start);
      store.buyEndChange(end);
      const Now = Date.parse(new Date());
      if (Now < startTime.value) {
        store.presaleChange("预售未开始");
      } else if (Now < endTime.value) {
        store.presaleChange("预售开始");
      } else {
        store.presaleChange("预售结束");
      }
    }
  }, 1000);

  return () => {
    // 清楚计时器 在做一个domobj动态效果的时候,事件里定时器应该先清除在设置,防止多次点击直接生成多个定时器,所以每生成一次事件需要清除一次定时器,达到始终保持事件里只有一个定时器。
    clearInterval(timer);
  };
}
