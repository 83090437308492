export { default as ERC20ABI } from "./ABI/ERC20ABI.json";
export { default as IDOABI } from "./ABI/IDOABI.json";
import PledgeABI from "./ABI/PledgeABI.json";
import ERC20ABI from "./ABI/ERC20ABI.json";


const bscExplorer = { name: "BscScan", url: "https://bscscan.com" };
export const Huiyuan = {
  // 'www.liuheworld.cc': '0xF4Aa9A8Ef5261fAE389D9EAB977162478f587908',
  // '127.0.0.1:5173': '0x844200a2a4d3f890ccee7d76125d542fb64f5555',
  // 'chainlist.yagong.xyz': '0xF2E4D615bFC81758533f27a1C694cF461e77C13E'
}

const bsc = {
  chainId: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  rpcUrls: {
    public: "https://bsc-dataseed4.defibit.io",
    default: "https://bsc-dataseed2.ninicoin.io",
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18,
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 15921452,
  },
};
export const bscTest = {
  chainId: 97,
  name: "BNB Smart Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Chain Native Token",
    symbol: "tBNB",
  },
  rpcUrls: {
    public: "https://data-seed-prebsc-1-s2.binance.org:8545/",
    default: "https://data-seed-prebsc-1-s2.binance.org:8545/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" },
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 17422483,
  },
  testnet: true,
};
export const CORE = {
  chainId: 1116,
  name: "CORE",
  network: "CORE",
  nativeCurrency: { name: "CORE", symbol: "CORE", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.coredao.org/",
  },
  blockExplorers: {
    default: {
      name: "CORE",
      url: "https://scan.coredao.org",
    },
  },
  testnet: true,
};
export const ARB = {
  id: 42161,
  name: "Arbitrum",
  network: "Arbitrum",
  rpcUrls: {
    default: "https://arb1.arbitrum.io/rpc",
  },
  nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  blockExplorers: {
    default: {
      name: "ARB",
      url: "https://arbiscan.io",
    },
  },
};
export const BASE = {
  id: 8453,
  name: "BASE",
  network: "BASE",
  rpcUrls: {
    default: "https://developer-access-mainnet.base.org",
  },
  nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  blockExplorers: {
    default: {
      name: "BASE",
      url: "https://basescan.org",
    },
  },
};
export const NetWorkConfig = {
  56: bsc,
  97: bscTest,
  1116: CORE,
  42161: ARB,
  8453: BASE,
};
