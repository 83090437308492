import { defineStore } from "pinia";

// defineStore 方法有两个参数，第一个参数是模块化名字（也就相当于身份证一样，不能重复）
export const useStore = defineStore("main", {
  state: () => ({
    // 存放的就是模块的变量
    ownadress: "连接钱包",
    account: "",
    idoAddress: "", // ido 地址
    superAddress: "", // 上级地址
    startTime: 0, //
    endTime: 1665367810000, //
    maxAmount: 0, //
    minAmount: 0,
    tokenExchangeRate: 0, //
    rebate: [],
    tokenName: "", //
    collectionAddress: "", //
    logoLink: "", //
    groupLink: "", //
    amount_USDT: 0,
    amount: 0, // 购买数
    claim: 0, // 代币数
    divdAmount: 0, // 自己佣金
    middleIamge: ``,
    backImage: ``,
    payToken: { address: "", symbol: "USDT", decimals: 18 },
    isRepeat: false,
    isRewardType: false,
    isEmpty: false, //空撸
    timing: 0,
    chainId: "",
    isShowTotal: false, // 是否显示总参与金额
    totalAmount: "", // 总金额
    day: "00",
    hour: "00",
    minute: "00",
    second: "00",
    buyStart: "",
    buyEnd: "",
    url: "",
    presale: "",
    title: "",
    HookInfo: {
      youGet: `You Can Get`,
      userAmount: `User Buy Amount`,
      brokerage: `Rebate`,
      level_amount: `Level User Purchase Amount`,
    },
    balance: "",
    claimText:"收到"
  }),
  getters: {
    // 相当于vue里面的计算属性，可以缓存数据
  },
  actions: {
    // 可以通过actions 方法，改变 state 里面的值。
    ownadressChange(payload) {
      this.ownadress = payload;
    },
    claimTextChange(payload) {
      this.claimText = payload;
    },
    balanceChange(payload) {
      this.balance = payload;
    },
    setAccount(content) {
      this.account = content;
    },

    tokenNameChange(payload) {
      this.tokenName = payload;
    },
    logoLinkChange(payload) {
      this.logoLink = payload;
      console.log(this.logoLink)
    },
    minAmountChange(payload) {
      this.minAmount = payload;
    },
    maxAmountChange(payload) {
      this.maxAmount = payload;
    },
    startTimeChange(payload) {
      this.startTime = payload;
    },
    endTimeChange(payload) {
      this.endTime = payload;
    },
    dayChange(payload) {
      this.day = payload;
      // console.log(this.timing)
    },
    hourChange(payload) {
      this.hour = payload;
      // console.log(this.timing)
    },
    minuteChange(payload) {
      this.minute = payload;
      // console.log(this.timing)
    },
    secondChange(payload) {
      this.second = payload;
      // console.log(this.timing)
    },
    buyStartChange(payload) {
      this.buyStart = payload;
      // console.log(this.timing)
    },
    buyEndChange(payload) {
      this.buyEnd = payload;
      // console.log(this.timing)
    },
    presaleChange(payload) {
      this.presale = payload;
    },
    chainIdChange(payload) {
      this.chainId = payload;
    },
    idoAddressChange(payload) {
      this.idoAddress = payload;
    },
    supperAddressChange(payload) {
      this.superAddress = payload;
    },
    addressPayTokenChange(payload) {
      this.payToken.address = payload;
    },
    symbolPayTokenChange(payload) {
      this.payToken.symbol = payload;
    },
    rebateChange(payload) {
      this.rebate = payload;
    },
    titleChange(payload) {
      this.title = payload;
    },
    tokenExchangeRateChange(payload) {
      this.tokenExchangeRate = payload;
    },
    amountChange(payload) {
      this.amount = payload;
    },
    claimChange(payload) {
      this.claim = payload;
    },
    divdAmountChange(payload) {
      this.divdAmount = payload;
    },
    urlChange(payload) {
      this.url = payload;
      console.log(this.url, "this.url");
    },
  },
});
