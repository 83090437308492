<template>
  <div id="d">
    <div class="root">
      <div class="center">
        <p>{{ $t("home.huoban") }}</p>
        <div>
          <img src="../../../assets/图层 31.png" alt="" />
          <img src="../../../assets/图层 26.png" alt="" />
          <img src="../../../assets/图层 27.png" alt="" />
          <img src="../../../assets/图层 28.png" alt="" />
          <img src="../../../assets/29.png" alt="" />
          <img src="../../../assets/图层 30.png" alt="" />
          <img src="../../../assets/图层 25.png" alt="" />
          <img src="../../../assets/图层 24.png" alt="" />
        </div>
      </div>
      <div class="foot">
        <div class="left">
          <img :src="store.logoLink" alt="" />
          <span>{{ $t("home.sheji") }}{{tokenName}}</span>
        </div>
        <div class="right">
          <img src="../../../assets/推特 twitter.png" alt="" />
          <img src="../../../assets/TelegramFilled.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { chainId, useStore } from "../../../store";
import { storeToRefs } from "pinia";
const store = useStore();
const {

  tokenName
  
} = storeToRefs(store);
// const { logoLink } = storeToRefs(store);
</script>
<style scoped>
.root {
  /* height: 400px; */
  color: #fff;
}

.center {
  margin: 0 auto;
  overflow: hidden;
  background-image: url("../../../assets/图层 29.png");
  background-size: 100% 100%;
}

.center p {
  margin-top: 104px;

  font-size: 62px;
  text-align: center;
  font-family: "造字工房悦圆（非商用）";
}

.center div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 82px 71px;
}

.center div img {
  margin-bottom: 29px;
  width: 24%;
}

.foot {
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  align-items: center;
  margin-top: 200px;

  padding-bottom: 78px;
  box-sizing: border-box;
}

.left span {
  font-size: 26px;
  margin-right: 10px;
  color: #b3b3b3;
  font-family: Microsoft YaHei;
}

.left {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left img {
  width: 100px;
}
.foot .right {
  display: flex;
  justify-content: space-around;
}
.right img:nth-child(1) {
  margin-left: 80px;
}
.right img:nth-child(2) {
  margin-left: 30px;
}

@media screen and (max-width: 800px) {
  .root {
    padding: 0;
  }

  .center p {
    font-size: 24px;
    padding: 25px 0;
    margin: 0;
  }

  .root .center div {
    padding: 0 11px;
    margin: 0;
  }

  .center div img {
    width: 49%;
    margin-bottom: 7px;
  }

  .foot {
    margin: 0;
    padding: 33px 22px;
  }

  .left > img {
    /* width: 110px; */
    width: 41px;
  }

  .root .right img {
    width: 33px;
    height: 28px;
    margin-left: 15px;
  }

  .left {
    flex-direction: column;
    align-items: flex-start;
  }

  .left span {
    font-size: 14px;
    margin-top: 12px;
  }

  .right {
    margin-top: 40px;
  }
}
</style>
