import i18n from "./language";
import { createApp,Vue } from "vue";
import App from "./App.vue";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import pinia from "./store/pinia";
import VueI18n from "vue-i18n";
createApp(App)
  .use(i18n)
  .use(pinia)
  .use(ElementPlus)
  .use(VueI18n)
  .use(ElementPlus)
  .mount("#app");

