<template>
  <div class="root">
    <div id="a">
      <div class="center">
        <div class="presale">
          <div class="pre">{{ presale }}</div>
          <p>
            {{ $t("home.minmairu") }}
            <span>{{ store.minAmount }} {{ payToken.symbol }}</span
            >,
            {{ $t("home.maxmairu") }}
            <span>{{ maxAmount }} {{ payToken.symbol }}</span>
          </p>
          <div class="time">
            <div>
              <p id="day">{{ day }}</p>
              <span>{{ $t("home.tian") }}</span>
            </div>
            <div>
              <p id="hour">{{ hour }}</p>
              <span>{{ $t("home.xiaoshi") }}</span>
            </div>
            <div>
              <p id="minute">{{ minute }}</p>
              <span>{{ $t("home.minute") }}</span>
            </div>
            <div>
              <p id="second">{{ second }}</p>
              <span>{{ $t("home.miao") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="b">
      <div class="buy">
        <div class="left">
          <p>
            {{ $t("home.jiage") }} ： {{ tokenExchangeRate }} {{ tokenName }} /
            1
            {{ payToken.symbol }}
          </p>
          <p>
            {{ $t("home.shijian") }} ：<span id="start">{{ buyStart }}</span>
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              id="end"
              >{{ buyEnd }}</span
            >
          </p>
          <div class="tai">
            <img
              style="width: 60px; height: 60px"
              :src="`/images/${url}.png`"
            />

            <span>{{ balance }} {{ payToken.symbol }}</span>
          </div>
          <div class="count">
            <div style="text-align: center">
              {{ $t("home.min") }}
              <br />
              {{ store.minAmount }}{{ payToken.symbol }}
            </div>
            <div class="Stepper">
              <div @click="decrement">
                <img src="../../../assets/图层 5.png" alt="" />
              </div>
              <!-- <span>{{ count }}</span> -->
              <input type="text" v-model="count" />

              <div @click="increment">
                <img src="../../../assets/加.png" alt="" />
              </div>
            </div>
            <div style="text-align: center">
              {{ $t("home.max") }}<br />{{ store.maxAmount
              }}{{ payToken.symbol }}
            </div>
          </div>
          <el-button plain class="buyClick" @click="buyClick">
            {{ $t("home.goumai") }}
          </el-button>
        </div>
        <div class="right">
          <img src="../../../assets/组 16.png" alt="" />
          <p>
            {{ $t("home.baochou") }}：{{ divdAmount }} {{ payToken.symbol }}
          </p>
          <p>{{ $t("home.shuliang") }}：{{ amount }} {{ tokenName }}</p>
          <button @click="claimTokenHandler" style="cursor: pointer">
            {{ $t("home.shoudao") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useGetTimingTime } from "../../../hook";
import { getIdoV2Config, isZero } from "../../../utils";
import { BigNumber, ethers } from "ethers";
import {ERC20ABI, IDOABI, NetWorkConfig} from "../../../config";
import { ref, onMounted, computed, watch } from "vue";
import isAddress from "../../../utils/isAddress";
import { useStore } from "../../../store";
import { storeToRefs } from "pinia";
import axios from "axios";
import { addToNetwork, switchEthereumChain } from "../../../utils/metamask";
import { ElMessage, ElMessageBox } from "element-plus";
import { Action } from "element-plus";
import Web3 from "web3";
import { line, claimTokenAction } from "../../../utils/metamask";
const store = useStore();
const {
  minAmount,
  maxAmount,
  logoLink,
  timing,
  tokenName,
  startTime,
  endTime,
  tokenExchangeRate,
  day,
  hour,
  minute,
  second,
  buyStart,
  buyEnd,
  presale,
  amount,
  chainId,
  idoAddress,
  payToken,
  superAddress,
  account,
  divdAmount,
  claim,
  url,
  balance,
  claimText,
} = storeToRefs(store);
// console.log(url.value,'url000000000')
const count = ref(0);
const switchHandler = async (chainId_) => {
  try {
    await switchEthereumChain(chainId_);
    // console.log("switchEthereumChain");
    // console.log(store.idoAddress,store.chainId)
    getIdoConfigHandler(store.idoAddress, store.chainId);
    // console.log('getIdoConfigHandler')
  } catch (error) {
    console.log(error);
  }
};

watch(chainId, () => {
  // console.log(chainId);
  // console.log(newValue, "newValue", oldValue, "oldValue");
  // console.log(idoAddress && account, "(store.idoAddress && store.account)");
  if (idoAddress && account) {
    try {
      if (window.ethereum?.isMetaMask && chainId) {
        // 监听
        console.log(window.ethereum?.networkVersion, "ethereum.networkVersion");
        console.log(chainId.value, "chainId");
        if (window.ethereum?.networkVersion !== chainId.value) {
          line();
          switchHandler(chainId);
          console.log("1111111111111");
        } else {
          console.log("666666666666666");
          linkWalletHandle();
        }
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    ElMessage({
      message: `请先连接钱包`,
      type: "error",
    });
  }
});
const yue = async () => {
  if(window.ethereum){
    const web3 = new Web3(window.ethereum);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];
    web3.eth
        .getBalance(account)
        .then((balance) => {
          const balanceInEther = web3.utils.fromWei(balance, "ether");
          store.balanceChange(Number(balanceInEther).toFixed(2));
          // console.log("ETH balance:", balanceInEther);
        })
        .catch((error) => {
          console.error("Failed to get balance:", error);
        });
  }


};
const linkWallet = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const account = accounts[0];
  return account;
};
const linkWalletHandle = () => async (e) => {
  const account = await linkWallet();
  store.accountChange(account);
  // console.log('00000000000000000000')
  getUserInfoAction();
};
// const getUserInfo = (account, library, IDO_ADDRESS) => {
//   try {
//     const contract = new ethers.Contract(IDO_ADDRESS, IDOABI, library);
//     const info = contract.getUserInfo(account);
//     console.log(info, "info");

//     return info;
//   } catch (error) {
//     console.log(error);
//   }
// };
// 获取用户信息操作
const getUserInfoAction = async () => {
  if(window.ethereum){
    const decimals = store.payToken.decimals;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const contract = new ethers.Contract(idoAddress.value, IDOABI, provider);
    // const info = contract.getUserInfo(account);


    try {
      const contract = new ethers.Contract(idoAddress.value, IDOABI, provider);
      // console.log(account.value,'account1111111111111')
      const info = await contract.getUserInfo(account.value);
      // console.log(info,'info.amount')
      const _amount = ethers.utils.formatUnits(info.amount, decimals);
      // console.log(_amount)
      const _claim = ethers.utils.formatEther(info.claim);
      const _divdAmount = ethers.utils.formatEther(info.divdAmount);
      store.amountChange(_amount);
      store.claimChange(_claim);
      store.divdAmountChange(_divdAmount);
    } catch (error) {
      console.log(error);
    }
  }

};

onMounted(() => {

  // 获取ido地址
  getIdoAddress();
  // 获取到ido地址后 调用获取用户信息函数传入ido地址 chain
  getIdoConfigHandler(store.idoAddress, store.chainId);
  yue();
});
// 领取奖励操作
const claimTokenHandler = () => {
  if (balance.value <= 0) {
    ElMessage({
      message: `暂未开放领取`,
      type: "error",
    });
    return;
  }
  try {
    claimTokenAction();
  } catch (error) {
    // ElMessage({
    //   message: `您必须参加ido才能获得推荐奖励`,
    //   type: "error",
    // });
    console.log(error);
  }
};
const getHostIdo = async () => {
  // 返回当前URL的主机名：
  const yuming = location.hostname;
  console.log(yuming, 7);
  const res = await axios({
    method: "get",
    url: "https://ng.ggg.dog/get",
    params: {
      yuming: yuming,
    },
  });
  return res;
};
// 获取ido地址
const getIdoAddress = () => {
  let _idoAddress = "";
  let _chain = "";
  //用该属性获取页面 URL 地址：
  const host = window.location.search;
  if (host) {
    //页面跳转的时候，获取某个参数
    let params = new URLSearchParams(host);
    console.log(params);
    // 获取ido地址 上级地址 链id
    let [id, invite, chainId] = [
      params.get("addr"),
      params.get("invite"),
      params.get("chainId"),
    ];
    _idoAddress = id;
    _chain = chainId;
    store.chainIdChange(_chain);
    store.supperAddressChange(invite);
    store.idoAddressChange(_idoAddress);
  }
};
// 图片地址 标题
const changeTitleLogo = (icon, title) => {
  const changeFavicon = (link) => {
    let $favicon = document.querySelector('link[rel="icon"]');
    // If a <link rel="icon"> element already exists,
    // change its href to the given link.
    if ($favicon !== null) {
      $favicon.href = link;
      // Otherwise, create a new element and append it to <head>.
    } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = link;
      document.head.appendChild($favicon);
    }
  };
  changeFavicon(icon); // 动态修改网站图标
  document.title = title.value; // 动态修改网站标题
  console.log(document.title, "document.title");
};

// 获取用户信息
const getIdoConfigHandler = async (_idoAddress, _chain) => {
  try {
    console.log(chainId.value)
    const rpc = NetWorkConfig[chainId.value].rpcUrls.default

    const provider = new ethers.providers.JsonRpcProvider(rpc)

    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // 合约
    const ido_daiContract = new ethers.Contract(_idoAddress, IDOABI, provider);
    // 调用getConfig函数（异步）获取用户信息
    const idoInfo = await ido_daiContract.getConfig();
    console.log(idoInfo, "idoInfo");
    const name_ = idoInfo[0][0];
    const logoLink_ = idoInfo[0][1];
    const startTime_ = Number(idoInfo[1][0]) * 1000;
    const endTime_ = Number(idoInfo[1][1]) * 1000;
    const _minAmount = ethers.utils.formatEther(idoInfo[1][2]);
    const _maxAmount = ethers.utils.formatEther(idoInfo[1][3]);
    const _tokenExchangeRate = Number(idoInfo[1][4]) / 10000;
    const _payToken = idoInfo[2][0];
    // 上级地址
    // const collectionAddr_ = idoInfo[2][1];
    // const _isRepeat = idoInfo[3][0];
    // const _isRewardType = idoInfo[3][1];
    // const _isEmpty = idoInfo[3][2];
    const _proxyLayer = idoInfo[4].map((item) => Number(item) / 100);
    if (_proxyLayer) {
      store.rebateChange(_proxyLayer);
      let _title = store.HookInfo.youGet;
      const length = _proxyLayer.length;
      if (length <= 0) return;
      if (length === 1) {
        const str =
          _title +
          store.HookInfo.userAmount +
          _proxyLayer[0] +
          "%" +
          store.HookInfo.brokerage;
        store.titleChange(str);
      } else {
        let _dou = ",";
        let _title = store.HookInfo.youGet;
        for (let i = 0; i < length; i++) {
          if (i === length - 1) _dou = "。";

          const str =
            i +
            1 +
            store.HookInfo.level_amount +
            _proxyLayer[i] +
            "%" +
            store.HookInfo.brokerage +
            _dou;
          _title = _title.concat(str);
          store.titleChange(_title);
        }
      }
    }

    if (name_) {
      store.tokenNameChange(name_);
    }
    if (logoLink_) {
      store.logoLinkChange(
        logoLink_.replace("ipfs://", "https://ipfs.ggg.dog/ipfs/")
      );
    }
    if (_minAmount) {
      store.minAmountChange(_minAmount);
    }
    if (_maxAmount) {
      store.maxAmountChange(_maxAmount);
    }
    if (startTime_) {
      store.startTimeChange(startTime_);
    }
    if (endTime_) {
      store.endTimeChange(endTime_);
    }
    if (_payToken) {
      // console.log(_payToken,'_payToken')
      store.addressPayTokenChange(_payToken);
    }
    if (_tokenExchangeRate) {
      store.tokenExchangeRateChange(_tokenExchangeRate);
    }
    changeTitleLogo(
      logoLink.value.replace("ipfs://", "https://ipfs.ggg.dog/ipfs/"),
      tokenName
    );
    let url = "";
    let symbol = "";
    if (isZero(_payToken)) {

      switch (provider._network.chainId) {
        case 56:
          symbol = "BNB";
          url = "bnb";
          break;
        case 97:
          symbol = "TBNB";
          url = "bnb";
          break;
        case 1116:
          symbol = "CORE";
          url = "core";
          break;
        default:
          symbol = "TBNB";
          url = "bnb";
          break;
      }
    }else{
      console.log("1111","symbol")
      const tokenContract = new ethers.Contract(_payToken, ERC20ABI, provider);
      symbol =await  tokenContract.symbol();
      url =symbol;
      if(url!=undefined){
        url = url.toLowerCase();
      }
    }
    store.symbolPayTokenChange(symbol);
    store.urlChange(url);
    useGetTimingTime();
    getUserInfoAction();
  } catch (error) {
    console.log(error);
  }
};
function increment() {
  count.value++;
}

function decrement() {
  if (count.value > 0) {
    count.value--;
  }
}

// 点击支付
const buyClick = async () => {
  if (!store.account) {
    ElMessage({
      message: `请先连接钱包`,
      type: "error",
    });
    return;
  }
  const newTime = new Date().getTime();
  if (newTime < startTime.value) {
    return ElMessageBox.alert("活动未开始", "提示", {
      confirmButtonText: "OK",
    });
  }

  if (newTime > endTime.value) {
    return ElMessageBox.alert("活动已结束", "提示", {
      confirmButtonText: "OK",
    });
  }
  if (amount.value + count.value > maxAmount.value) {
    return ElMessageBox.alert("超过最大购买数量", "提示", {
      confirmButtonText: "OK",
    });
  }
  if (count.value < minAmount.value) {
    count.value = minAmount.value;
  }
  if (count.value > maxAmount.value) {
    count.value = maxAmount.value;
  }
  try {
    const _account = store.superAddress ? store.superAddress : store.account;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // 签名者
    const signer = provider.getSigner();
    // console.log(signer, "signer");
    // 支付合约
    const IDOContract = new ethers.Contract(idoAddress.value, IDOABI, signer);
    console.log(isZero(payToken.value.address));
    if (isZero(payToken.value.address)) {
      const _amount = ethers.utils.parseEther(count.value.toString());
      // console.log(BigNumber(_amount),'_amount')
      await (
        await IDOContract.invest(_account, _amount.toString(), {
          value: _amount.toString(),
        })
      ).wait();
      ElMessage({
        message: `购买成功`,
        type: "success",
      });
    } else {
      const payTokenContract = new ethers.Contract(
        payToken.value.address,
        ERC20ABI,
        signer
      );
      const _amount = ethers.utils.parseUnits(
        count.value,
        payToken.value.decimals
      );
      const allowance = await payTokenContract.allowance(
        store.account,
        idoAddress.value
      );
      // console.log(ethers.utils.formatEther(allowance), idoAddress);
      if (_amount.gt(allowance)) {
        await (
          await payTokenContract.approve(idoAddress.value, _amount.toString())
        ).wait();
      }
      await (await IDOContract.invest(_account, _amount.toString())).wait();
    }
    getUserInfoAction();
  } catch (error) {
    console.log(error);
    ElMessage.error("error");
    console.log(89);
  }
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.root {
  background: #010000;
  background-image: url("../../../assets/bg.png");
  background-size: 100% 100%;
}

.center {
  margin: 0 auto;
  position: relative;
}

.center img {
  width: 100%;
  margin-top: 184px;
}

.center .presale {
  color: #fff;
  text-align: center;
}

.presale .pre {
  font-size: 90px;
  font-family: zihun5hao-wuwairunheiti;
}

.presale > p {
  font-size: 26px;
  margin-top: 42px;
  font-family: MicrosoftYaHei;
}

.presale span {
  color: #f68716;
}

.time {
  display: flex;
  justify-content: space-between;
  margin-top: 74px;
}

.presale .time div {
  width: 145px;
  height: 145px;
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #1a1a1a, #1a1a1a),
    linear-gradient(0deg, #422d4c, #000, #fff);
  border-radius: 25px;
}

.border1 div {
  width: 143px;
  border-radius: 25px;
  height: 143px;
  background: #26211b;
  margin: 2px 1px 0px 1px;
}

.presale .time div p {
  line-height: 140px;
  font-size: 54px;
}

.presale .time div span {
  font-size: 24px;
  color: #fff;
  /* line-height: 40px; */
}

.buy {
  width: 1383px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin: 310px auto 0;
}

.buy .left {
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right bottom, #1a1a1a, #1a1a1a),
    linear-gradient(114deg, #f0ab48, #1a1a1a, #cb844f);
  font-size: 30px;
  line-height: 42px;
  width: 666px;
  padding: 50px;
  height: 638px;
  border-radius: 45px;
}

.buy .right {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(145deg, #1a1a1a, #1a1a1a, #2a1e2b),
    linear-gradient(114deg, #ffffff, #422d4c);
  border: 1px solid transparent;
  font-size: 30px;
  line-height: 80px;
  width: 666px;
  padding: 50px;
  height: 638px;
  border-radius: 45px;
}

.left p {
  margin-bottom: 30px;
}

.tai {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.tai span {
  margin-left: 50px;
}

.count {
  display: flex;
  justify-content: space-between;
  color: #ccc;
}

.buyClick {
  width: 566px;
  height: 80px;
  background: linear-gradient(90deg, #f09d25 0%, #fe6b01 100%);
  border-radius: 16px;
  font-size: 30px;
  color: #fff;
  border: none;
  margin-top: 47px;
}

.right button {
  font-size: 30px;
  color: #f78413;
  width: 564px;
  height: 80px;
  border-radius: 16px;
  border: 1px solid #ffffff;
  margin-top: 12px;
}

.right p:nth-child(2) {
  margin-top: -75px;
}

.Stepper {
  width: 285px;
  height: 82px;
  border: 1px solid #ec7e1f;
  border-radius: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.Stepper div {
  cursor: pointer;
}
.Stepper input {
  width: 120px;
  background: #1a1a1a;
  border: none;
  outline: none;
  color: #fff;
  text-align: center;
  font-size: 30px;
}

.Stepper div:nth-child(1) {
  border-right: 1px solid #333;
  padding-right: 10px;
  width: 100%;
}

.Stepper div:nth-child(3) {
  padding-left: 10px;
  border-left: 1px solid #333;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .center .presale {
    width: 608px;
    margin: 0 auto;
    padding-top: 168px;
  }

  .Stepper {
    line-height: 60px;
  }

  .presale .time div span {
    line-height: 80px;
  }
}

@media screen and (max-width: 800px) {
  .root {
    background-image: url("../../../assets/27.png");
  }
  .presale .time div:nth-child(1) {
    background-image: linear-gradient(0, #2d1e30, #2b1e2e),
      linear-gradient(0deg, #422d4c, #000, #fff);
  }
  .root .center {
    padding: 120px 13px 76px;
  }

  .presale .pre {
    font-size: 52px;
  }

  .center {
    padding-top: 100px;
  }

  .center .presale {
    width: 348px;
    margin: 0 auto;
  }

  .center .presale > p {
    width: 100%;
    font-size: 14px;
    margin-top: 20px;
  }

  .presale .time {
    width: 100%;
  }

  .presale .time div {
    width: 84px;
    border-radius: 12px;
    height: 84px;
  }

  .presale .time div p {
    font-size: 30px;
    width: 84px;
    height: 84px;
    line-height: 88px;
  }

  .presale .time div span {
    font-size: 14px;
    color: #cccccc;
    line-height: 44px;
  }

  .time {
    margin-top: 42px;
  }

  .buy {
    display: inherit;
    padding: 0 21px;
    margin: 0;
    width: 100%;
  }
  .buy .left {
    background-image: linear-gradient(to right bottom, #1a1a1a, #000, #2b1e2e),
      linear-gradient(114deg, #f0ab48, #1a1a1a, #cb844f);
  }
  .buy .left,
  .buy .right {
    height: 350px;
    border-radius: 22px;
    margin: 0 auto;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 20px;
  }

  .buy .right {
    height: 160px;
    border-radius: 22px;
  }

  .left p {
    margin: 10px 0;
  }

  .buy .right {
    margin-top: 25px;
  }

  .left .Stepper {
    width: 142px;
    height: 41px;
    border-radius: 9px;
  }

  .left .Stepper img {
    width: 14px;
    height: 14px;
  }

  .tai img {
    width: 30px !important;
    height: 30px !important;
  }

  .tai span {
    margin-left: 25px;
  }

  .buyClick {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #f09d25 0%, #fe6b01 100%);
    font-size: 14px;
    border-radius: 8px;
    border: none;
  }

  .right img {
    display: none;
  }

  .right button {
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    height: 40px;
    color: #f78413;
    border: none;
  }

  .right p:nth-child(2) {
    margin: 0;
    line-height: 36px;
  }

  .count div {
    color: #ccc;
  }

  .right button {
    margin-top: 24px;
  }
  .Stepper input {
    width: 60px;
    background: #0b080c;
    border: none;
    outline: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
  }
}
</style>
