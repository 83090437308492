export default {
  home: {
    language: "中文",
    qianbao: "连接钱包",
    minmairu: "最小买入",
    maxmairu: "最大买入",
    tian: "天",
    xiaoshi: "时",
    minute: "分",
    miao: "秒",
    jiage: "价格",
    shijian: "时间",

    taidabi: "泰达币",
    goumai: "购买",
    minmeiyuan: "Award",
    baochou: "奖励",
    shuliang: "数量",
    shoudao: "收到",
    guize: "邀请规则",
    canjiaido: "您必须参加ido才能获得推荐奖励：",
    lianjie: "邀请链接",
    fuzhi: "复制",
    huoban: "我们的伙伴",
    sheji: "© 2024. 设计者: ",
    shouye:"首页",
    yaoqing:"邀请",
    canyu:"参与",
    hezuo:"合作",
    meiyuan:"美元",
    min:"最小",
    max:"最大",
    yushouweikaishi:"预售开始",
    yushoukaishi:"预售开始",
    yushoujieshu:"预售结束",
  },
};
