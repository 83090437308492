/**
 * 倒计时
 * @param date 毫秒
 * @returns D H M S
 */
const showtime = function (date) {
    const nowtime = new Date();  //获取当前时间 

    const lefttime = date.value - nowtime.getTime();
    // console.log(date,198)
    // console.log(nowtime.getTime(),199)
    // console.log(lefttime)
    if (lefttime < 0) return ["00", "00", "00", "00"]
  
    const day = Math.floor(lefttime / (1000 * 60 * 60 * 24)) //计算天数
    let d = `${day}`
    if (day < 10) d = `0${day}`
  
    const hour = Math.floor(lefttime / (1000 * 60 * 60) % 24) //计算小时数
    let h = `${hour}`
    if (hour < 10) h = `0${hour}`
  
    const min = Math.floor(lefttime / (1000 * 60) % 60) //计算分钟数
    let m = `${min}`
    if (min < 10) m = `0${min}`
  
    const sec = Math.floor(lefttime / 1000 % 60);  //计算秒数
    let s = `${sec}`
    if (sec < 10) s = `0${sec}`
    
    return [d, h, m, s];  //返回倒计时的字符串
  }
  
  export default showtime;