<template>
  <div style="background:#000">
    <HeaderView></HeaderView>
    <PresaleView></PresaleView>
    <InviteView></InviteView>
    <FooterView></FooterView>
  </div>
  <router-view />
</template>

<script>
  import HeaderView from './components/IDO/HeaderView.vue'
  import PresaleView from './components/IDO/Content/PresaleView.vue'
  import InviteView from './components/IDO/Content/InviteView.vue'
  import FooterView from './components/IDO/Content/FooterView.vue'
  export default {
    components: {
      HeaderView,
      PresaleView,
      InviteView,
      FooterView
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
</style>